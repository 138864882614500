import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import logo from './logo.png'; // Ensure logo.png is in the correct path
import graphImage from './graph.png'; // Replace with the actual graphic
import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import ReturnPolicy from './ReturnPolicy';

const Container = styled.div`
  background-color: #000;
  color: #fff;
  height: 100vh;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111;
  z-index: 1000;
  padding: 20px 10%;
  box-sizing: border-box;
`;

const Logo = styled.div`
  img {
    height: 40px;
  }
`;

const Nav = styled.nav`
  a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
`;

const TextContent = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 100px;
    font-size: 46px;
  }
`;

const CountdownContainer = styled.div`
  margin: 20px 0;
  font-size: 36px;
  font-weight: bold;
  color: white;
`;

const Newsletter = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  input[type="email"] {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    width: 300px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #6a0dad;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const GraphicContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 400px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Footer = styled.footer`
  background-color: #111;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100vw;
  box-sizing: border-box;

  a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;

    @media (max-width: 768px) {
      display: block;
      margin: 10px 0;
    }
  }
`;

const FooterLink = styled.a`
  color: #fff;
  margin: 0 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LandingPage = () => {
  return (
    <Container>
      <Header>
        <Logo>
          <img src={logo} alt="UEDNO Logo" />
        </Logo>
        {/* <Nav>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms and Conditions</Link>
          <Link to="/return">Return Policy</Link>
        </Nav> */}
      </Header>
      <MainContent>
        <TextContent>
          <Title>One intelligent platform for all your stores</Title>
          <CountdownContainer>
            <Countdown date={new Date('2024-12-01T00:00:00')} />
          </CountdownContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '20px' }}>
            <p>Subscribe to our newsletter to get the latest news and updates</p>
            <Newsletter>
              <input type="email" placeholder="Enter your email" />
              <button>Subscribe</button>
            </Newsletter>
          </div>
        </TextContent>
        <GraphicContent>
          <img src={graphImage} alt="Platform Analytics" />
        </GraphicContent>
      </MainContent>
      <Footer>
        <FooterLink href="/Privacy_Policy_for_Uedno.pdf" target="_blank">
          Privacy Policy
        </FooterLink>
        <FooterLink href="/Terms_and_Conditions_for_Uedno.pdf" target="_blank">
          Terms and Conditions
        </FooterLink>
        <FooterLink href="/Return_for_Uedno.pdf" target="_blank">
          Return Policy
        </FooterLink>
        {/* <FooterLink href="/CS_0421.pdf" target="_blank">
          Merchant Services Overview
        </FooterLink>
        <FooterLink href="/Merchant_Services_Apply_Now.pdf" target="_blank">
          Merchant Services Agreement
        </FooterLink>
        <FooterLink href="/OG_082522.pdf" target="_blank">
          Merchant Operating Guide
        </FooterLink>
        <FooterLink href="/PG_031522.pdf" target="_blank">
          Product Guide
        </FooterLink> */}
      </Footer>
    </Container>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/return" element={<ReturnPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;